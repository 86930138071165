@font-face {
    font-family: 'Avenir Next LT Pro Bold';
    src: url('../fonts/AvenirNextLTPro-Bold.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Bold Condensed';
    src: url('../fonts/AvenirNextLTPro-BoldCn.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-BoldCn.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Medium';
    src: url('../fonts/AvenirNextLTPro-Demi.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-Demi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Condensed';
    src: url('../fonts/AvenirNextLTPro-DemiCn.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-DemiCn.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Italic';
    src: url('../fonts/AvenirNextLTPro-It.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-It.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir Next LT Pro Regular';
    src: url('../fonts/AvenirNextLTPro-Regular.woff2') format('woff2'),
        url('../fonts/AvenirNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

